import Parse from "parse";












export class OD3_GTFS_Route_Type extends Parse.Object {
  static __initStatic() {this.className = "OD3_GTFS_Route_Type"}

  constructor(data) {
    super("OD3_GTFS_Route_Type", data );
  }

  get description() {
    return super.get("description");
  }
  set description(value) {
    super.set("description", value);
  }
  get transportation_device() {
    return super.get("transportation_device");
  }
  set transportation_device(value) {
    super.set("transportation_device", value);
  }
  get value() {
    return super.get("value");
  }
  set value(value) {
    super.set("value", value);
  }
} OD3_GTFS_Route_Type.__initStatic();

Parse.Object.registerSubclass("OD3_GTFS_Route_Type", OD3_GTFS_Route_Type);
