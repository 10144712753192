import Parse from "parse";





















export class OD3_Maintenance_Article extends Parse.Object {
  static __initStatic() {this.className = "OD3_Maintenance_Article"}

  constructor(data) {
    super("OD3_Maintenance_Article", data );
  }

  get body() {
    return super.get("body");
  }
  set body(value) {
    super.set("body", value);
  }
  get media() {
    return super.relation("media");
  }
  get subject() {
    return super.get("subject");
  }
  set subject(value) {
    super.set("subject", value);
  }
  get tenant() {
    return super.get("tenant");
  }
  set tenant(value) {
    super.set("tenant", value);
  }
  get ticket() {
    return super.get("ticket");
  }
  set ticket(value) {
    super.set("ticket", value);
  }
  get user() {
    return super.get("user");
  }
  set user(value) {
    super.set("user", value);
  }
} OD3_Maintenance_Article.__initStatic();

Parse.Object.registerSubclass("OD3_Maintenance_Article", OD3_Maintenance_Article);
