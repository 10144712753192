import Parse from "parse";



















export class OD3_Maintenance_Message_Body extends Parse.Object {
  static __initStatic() {this.className = "OD3_Maintenance_Message_Body"}

  constructor(data) {
    super("OD3_Maintenance_Message_Body", data );
  }

  get content() {
    return super.get("content");
  }
  set content(value) {
    super.set("content", value);
  }
  get data() {
    return super.get("data");
  }
  set data(value) {
    super.set("data", value);
  }
  get message() {
    return super.get("message");
  }
  set message(value) {
    super.set("message", value);
  }
  get tenant() {
    return super.get("tenant");
  }
  set tenant(value) {
    super.set("tenant", value);
  }
  get user() {
    return super.get("user");
  }
  set user(value) {
    super.set("user", value);
  }
} OD3_Maintenance_Message_Body.__initStatic();

Parse.Object.registerSubclass("OD3_Maintenance_Message_Body", OD3_Maintenance_Message_Body);
