import Parse from "parse";














export class OD3_BDE_Form extends Parse.Object {
  static __initStatic() {this.className = "OD3_BDE_Form"}

  constructor(data) {
    super("OD3_BDE_Form", data );
  }

  get entries() {
    return super.get("entries");
  }
  set entries(value) {
    super.set("entries", value);
  }
  get form() {
    return super.get("form");
  }
  set form(value) {
    super.set("form", value);
  }
  get name() {
    return super.get("name");
  }
  set name(value) {
    super.set("name", value);
  }
  get RID() {
    return super.get("RID");
  }
  set RID(value) {
    super.set("RID", value);
  }
  get storeUserInfo() {
    return super.get("storeUserInfo");
  }
  set storeUserInfo(value) {
    super.set("storeUserInfo", value);
  }
} OD3_BDE_Form.__initStatic();

Parse.Object.registerSubclass("OD3_BDE_Form", OD3_BDE_Form);
