import Parse from "parse";














export class OD3_WebPush extends Parse.Object {
  static __initStatic() {this.className = "OD3_WebPush"}

  constructor(data) {
    super("OD3_WebPush", data );
  }

  get label() {
    return super.get("label");
  }
  set label(value) {
    super.set("label", value);
  }
  get subscription() {
    return super.get("subscription");
  }
  set subscription(value) {
    super.set("subscription", value);
  }
  get user() {
    return super.get("user");
  }
  set user(value) {
    super.set("user", value);
  }
} OD3_WebPush.__initStatic();

Parse.Object.registerSubclass("OD3_WebPush", OD3_WebPush);
