import Parse from "parse";























export class OD3_Maintenance_Schedule_Execution_Step extends Parse.Object {
  static __initStatic() {this.className = "OD3_Maintenance_Schedule_Execution_Step"}

  constructor(data) {
    super("OD3_Maintenance_Schedule_Execution_Step", data );
  }

  get comment() {
    return super.get("comment");
  }
  set comment(value) {
    super.set("comment", value);
  }
  get description() {
    return super.get("description");
  }
  set description(value) {
    super.set("description", value);
  }
  get done() {
    return super.get("done");
  }
  set done(value) {
    super.set("done", value);
  }
  get group() {
    return super.get("group");
  }
  set group(value) {
    super.set("group", value);
  }
  get location() {
    return super.get("location");
  }
  set location(value) {
    super.set("location", value);
  }
  get protectivegear() {
    return super.get("protectivegear");
  }
  set protectivegear(value) {
    super.set("protectivegear", value);
  }
  get schedule() {
    return super.get("schedule");
  }
  set schedule(value) {
    super.set("schedule", value);
  }
  get tenant() {
    return super.get("tenant");
  }
  set tenant(value) {
    super.set("tenant", value);
  }
  get type() {
    return super.get("type");
  }
  set type(value) {
    super.set("type", value);
  }
  get usedmaterial() {
    return super.get("usedmaterial");
  }
  set usedmaterial(value) {
    super.set("usedmaterial", value);
  }
} OD3_Maintenance_Schedule_Execution_Step.__initStatic();

Parse.Object.registerSubclass("OD3_Maintenance_Schedule_Execution_Step", OD3_Maintenance_Schedule_Execution_Step);
