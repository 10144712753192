import Parse from "parse";














export class Locations extends Parse.Object {
  static __initStatic() {this.className = "Locations"}

  constructor(data) {
    super("Locations", data );
  }

  get name() {
    return super.get("name");
  }
  set name(value) {
    super.set("name", value);
  }
  get source() {
    return super.get("source");
  }
  set source(value) {
    super.set("source", value);
  }
  get spsid() {
    return super.get("spsid");
  }
  set spsid(value) {
    super.set("spsid", value);
  }
} Locations.__initStatic();

Parse.Object.registerSubclass("Locations", Locations);
