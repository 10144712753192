import Parse from "parse";















export class OWPlcDevice extends Parse.Object {
  static __initStatic() {this.className = "OWPlcDevice"}

  constructor(data) {
    super("OWPlcDevice", data );
  }

  get connectionString() {
    return super.get("connectionString");
  }
  set connectionString(value) {
    super.set("connectionString", value);
  }
  get enabled() {
    return super.get("enabled");
  }
  set enabled(value) {
    super.set("enabled", value);
  }
  get extraOptions() {
    return super.get("extraOptions");
  }
  set extraOptions(value) {
    super.set("extraOptions", value);
  }
  get interval() {
    return super.get("interval");
  }
  set interval(value) {
    super.set("interval", value);
  }
  get name() {
    return super.get("name");
  }
  set name(value) {
    super.set("name", value);
  }
  get options() {
    return super.get("options");
  }
  set options(value) {
    super.set("options", value);
  }
} OWPlcDevice.__initStatic();

Parse.Object.registerSubclass("OWPlcDevice", OWPlcDevice);
