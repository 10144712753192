 function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }import React from "react";
import {


  Kuenne_QATest,
  OD3_Source,
} from "../types";
import { useParseQuery } from "parse-hooks";
import dayjs from "dayjs";
import Parse from "parse";

export const useAkkordMetaData = (
  newEntry
) => {
  const userQuery = React.useMemo(() => {
    return new Parse.Query(Parse.User);
  }, []);
  const {
    result: users,
    loading: usersLoading,
    error: usersError,
    reload: usersReload,
  } = useParseQuery(userQuery);

  const orderQuery = React.useMemo(() => {
    const q = new Parse.Query(Kuenne_QATest)
      .addDescending("createdAt")
      .limit(1000);
    if (newEntry && newEntry.datum) {
      const dayDatum = dayjs(_optionalChain([newEntry, 'access', _ => _.datum, 'optionalAccess', _2 => _2.getTime, 'call', _3 => _3()]));

      q.greaterThanOrEqualTo(
        "AENDATETIME",
        dayDatum.startOf("month").subtract(1, "month").toDate()
      );
      q.lessThanOrEqualTo("AENDATETIME", dayDatum.endOf("month").toDate());
    }
    return q;
  }, [_optionalChain([newEntry, 'optionalAccess', _4 => _4.datum, 'optionalAccess', _5 => _5.getTime, 'call', _6 => _6()])]);

  const {
    result: orders,
    loading: ordersloading,
    error: orderserror,
    reload: ordersreload,
  } = useParseQuery(orderQuery);

  const sourceQuery = React.useMemo(() => {
    return new Parse.Query(OD3_Source);
  }, []);

  const {
    result: sources,
    loading: sourcesLoading,
    error: sourcesError,
    reload: sourcesReload,
  } = useParseQuery(sourceQuery);

  const machines = React.useMemo(() => {
    const machines = [] ;
    orders
      .filter((cOrder) => {
        const dayDatum = dayjs(_optionalChain([newEntry, 'access', _7 => _7.datum, 'optionalAccess', _8 => _8.getTime, 'call', _9 => _9()]));
        return (
          cOrder.AUFNR === _optionalChain([newEntry, 'access', _10 => _10.auftrag, 'optionalAccess', _11 => _11.AUFNR]) &&
          cOrder.AENDATETIME.getTime() > dayDatum.startOf("month").valueOf() &&
          cOrder.AENDATETIME.getTime() < dayDatum.endOf("month").valueOf()
        );
      })
      .forEach((order) => {
        const source = sources
          .filter((cSource) => !!cSource.tag)
          .find(
            (cSource) =>
              cSource.tag.toLowerCase() === "kuenne_werk1_m" + order.MASNR
          );
        console.log({ source });
        if (source) {
          if (
            machines.find(
              (cMachine) =>
                cMachine.tag.toLowerCase() === source.tag.toLowerCase()
            )
          ) {
            return;
          }
          machines.push(source);
        }
      });
    return machines;
  }, [_optionalChain([newEntry, 'optionalAccess', _12 => _12.auftrag, 'optionalAccess', _13 => _13.AUFNR]), sources, orders]);
  return {
    orders,
    sources,
    machines,
    users,
    usersLoading,
    usersError,
    usersReload,
    ordersloading,
    orderserror,
    ordersreload,
    sourcesLoading,
    sourcesError,
    sourcesReload,
  };
};
