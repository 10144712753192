import Parse from "parse";











export class OD3_BDE_Page extends Parse.Object {
  static __initStatic() {this.className = "OD3_BDE_Page"}

  constructor(data) {
    super("OD3_BDE_Page", data );
  }

  get data() {
    return super.get("data");
  }
  set data(value) {
    super.set("data", value);
  }
  get pageID() {
    return super.get("pageID");
  }
  set pageID(value) {
    super.set("pageID", value);
  }
} OD3_BDE_Page.__initStatic();

Parse.Object.registerSubclass("OD3_BDE_Page", OD3_BDE_Page);
