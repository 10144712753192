import Parse from "parse";



















export class OD3_GTFS_Trip extends Parse.Object {
  static __initStatic() {this.className = "OD3_GTFS_Trip"}

  constructor(data) {
    super("OD3_GTFS_Trip", data );
  }

  get bikes_allowed() {
    return super.get("bikes_allowed");
  }
  set bikes_allowed(value) {
    super.set("bikes_allowed", value);
  }
  get direction_id() {
    return super.get("direction_id");
  }
  set direction_id(value) {
    super.set("direction_id", value);
  }
  get route_id() {
    return super.get("route_id");
  }
  set route_id(value) {
    super.set("route_id", value);
  }
  get service_id() {
    return super.get("service_id");
  }
  set service_id(value) {
    super.set("service_id", value);
  }
  get trip_headsign() {
    return super.get("trip_headsign");
  }
  set trip_headsign(value) {
    super.set("trip_headsign", value);
  }
  get trip_short_name() {
    return super.get("trip_short_name");
  }
  set trip_short_name(value) {
    super.set("trip_short_name", value);
  }
  get wheelchair_accessible() {
    return super.get("wheelchair_accessible");
  }
  set wheelchair_accessible(value) {
    super.set("wheelchair_accessible", value);
  }
} OD3_GTFS_Trip.__initStatic();

Parse.Object.registerSubclass("OD3_GTFS_Trip", OD3_GTFS_Trip);
