import Parse from "parse";















export class OD3_BDE_Unit extends Parse.Object {
  static __initStatic() {this.className = "OD3_BDE_Unit"}

  constructor(data) {
    super("OD3_BDE_Unit", data );
  }

  get forms() {
    return super.relation("forms");
  }
  get image() {
    return super.get("image");
  }
  set image(value) {
    super.set("image", value);
  }
  get name() {
    return super.get("name");
  }
  set name(value) {
    super.set("name", value);
  }
  get source() {
    return super.get("source");
  }
  set source(value) {
    super.set("source", value);
  }
} OD3_BDE_Unit.__initStatic();

Parse.Object.registerSubclass("OD3_BDE_Unit", OD3_BDE_Unit);
