import Parse from "parse";
















export class OD3_Knowledge_Category extends Parse.Object {
  static __initStatic() {this.className = "OD3_Knowledge_Category"}

  constructor(data) {
    super("OD3_Knowledge_Category", data );
  }

  get label() {
    return super.get("label");
  }
  set label(value) {
    super.set("label", value);
  }
  get language() {
    return super.get("language");
  }
  set language(value) {
    super.set("language", value);
  }
  get parent() {
    return super.get("parent");
  }
  set parent(value) {
    super.set("parent", value);
  }
  get tenant() {
    return super.get("tenant");
  }
  set tenant(value) {
    super.set("tenant", value);
  }
} OD3_Knowledge_Category.__initStatic();

Parse.Object.registerSubclass("OD3_Knowledge_Category", OD3_Knowledge_Category);
