import Parse from "parse";


















export class OD3_Knowledge_Article extends Parse.Object {
  static __initStatic() {this.className = "OD3_Knowledge_Article"}

  constructor(data) {
    super("OD3_Knowledge_Article", data );
  }

  get category() {
    return super.get("category");
  }
  set category(value) {
    super.set("category", value);
  }
  get label() {
    return super.get("label");
  }
  set label(value) {
    super.set("label", value);
  }
  get language() {
    return super.get("language");
  }
  set language(value) {
    super.set("language", value);
  }
  get markdown() {
    return super.get("markdown");
  }
  set markdown(value) {
    super.set("markdown", value);
  }
  get tenant() {
    return super.get("tenant");
  }
  set tenant(value) {
    super.set("tenant", value);
  }
} OD3_Knowledge_Article.__initStatic();

Parse.Object.registerSubclass("OD3_Knowledge_Article", OD3_Knowledge_Article);
