import Parse from "parse";




















export class OD3_Knowledge_Video extends Parse.Object {
  static __initStatic() {this.className = "OD3_Knowledge_Video"}

  constructor(data) {
    super("OD3_Knowledge_Video", data );
  }

  get category() {
    return super.get("category");
  }
  set category(value) {
    super.set("category", value);
  }
  get descriptionMarkdown() {
    return super.get("descriptionMarkdown");
  }
  set descriptionMarkdown(value) {
    super.set("descriptionMarkdown", value);
  }
  get label() {
    return super.get("label");
  }
  set label(value) {
    super.set("label", value);
  }
  get language() {
    return super.get("language");
  }
  set language(value) {
    super.set("language", value);
  }
  get tenant() {
    return super.get("tenant");
  }
  set tenant(value) {
    super.set("tenant", value);
  }
  get thumbnailImage() {
    return super.get("thumbnailImage");
  }
  set thumbnailImage(value) {
    super.set("thumbnailImage", value);
  }
  get video() {
    return super.get("video");
  }
  set video(value) {
    super.set("video", value);
  }
} OD3_Knowledge_Video.__initStatic();

Parse.Object.registerSubclass("OD3_Knowledge_Video", OD3_Knowledge_Video);
