import Parse from "parse";


















export class OD3_Knowledge_Document extends Parse.Object {
  static __initStatic() {this.className = "OD3_Knowledge_Document"}

  constructor(data) {
    super("OD3_Knowledge_Document", data );
  }

  get category() {
    return super.get("category");
  }
  set category(value) {
    super.set("category", value);
  }
  get document() {
    return super.get("document");
  }
  set document(value) {
    super.set("document", value);
  }
  get label() {
    return super.get("label");
  }
  set label(value) {
    super.set("label", value);
  }
  get language() {
    return super.get("language");
  }
  set language(value) {
    super.set("language", value);
  }
  get tenant() {
    return super.get("tenant");
  }
  set tenant(value) {
    super.set("tenant", value);
  }
} OD3_Knowledge_Document.__initStatic();

Parse.Object.registerSubclass("OD3_Knowledge_Document", OD3_Knowledge_Document);
