import Parse from "parse";


















export class OD3_Knowledge_DocumentPage extends Parse.Object {
  static __initStatic() {this.className = "OD3_Knowledge_DocumentPage"}

  constructor(data) {
    super("OD3_Knowledge_DocumentPage", data );
  }

  get annotations() {
    return super.get("annotations");
  }
  set annotations(value) {
    super.set("annotations", value);
  }
  get document() {
    return super.get("document");
  }
  set document(value) {
    super.set("document", value);
  }
  get image() {
    return super.get("image");
  }
  set image(value) {
    super.set("image", value);
  }
  get label() {
    return super.get("label");
  }
  set label(value) {
    super.set("label", value);
  }
  get page() {
    return super.get("page");
  }
  set page(value) {
    super.set("page", value);
  }
  get tenant() {
    return super.get("tenant");
  }
  set tenant(value) {
    super.set("tenant", value);
  }
} OD3_Knowledge_DocumentPage.__initStatic();

Parse.Object.registerSubclass("OD3_Knowledge_DocumentPage", OD3_Knowledge_DocumentPage);
