import Parse from "parse";

























export class Kuenne_AkkordEintraege extends Parse.Object {
  static __initStatic() {this.className = "Kuenne_AkkordEintraege"}

  constructor(data) {
    super("Kuenne_AkkordEintraege", data );
  }

  get auftrag() {
    return super.get("auftrag");
  }
  set auftrag(value) {
    super.set("auftrag", value);
  }
  get datum() {
    return super.get("datum");
  }
  set datum(value) {
    super.set("datum", value);
  }
  get dichte() {
    return super.get("dichte");
  }
  set dichte(value) {
    super.set("dichte", value);
  }
  get durchmesser() {
    return super.get("durchmesser");
  }
  set durchmesser(value) {
    super.set("durchmesser", value);
  }
  get geschwindigkeit() {
    return super.get("geschwindigkeit");
  }
  set geschwindigkeit(value) {
    super.set("geschwindigkeit", value);
  }
  get gewicht() {
    return super.get("gewicht");
  }
  set gewicht(value) {
    super.set("gewicht", value);
  }
  get jahr() {
    return super.get("jahr");
  }
  set jahr(value) {
    super.set("jahr", value);
  }
  get maschine() {
    return super.get("maschine");
  }
  set maschine(value) {
    super.set("maschine", value);
  }
  get monat() {
    return super.get("monat");
  }
  set monat(value) {
    super.set("monat", value);
  }
  get werker() {
    return super.get("werker");
  }
  set werker(value) {
    super.set("werker", value);
  }
  get zaehlerEnde() {
    return super.get("zaehlerEnde");
  }
  set zaehlerEnde(value) {
    super.set("zaehlerEnde", value);
  }
  get zaehlerStart() {
    return super.get("zaehlerStart");
  }
  set zaehlerStart(value) {
    super.set("zaehlerStart", value);
  }
} Kuenne_AkkordEintraege.__initStatic();

Parse.Object.registerSubclass("Kuenne_AkkordEintraege", Kuenne_AkkordEintraege);
