import Parse from "parse";











export class OD3_GTFS_Level extends Parse.Object {
  static __initStatic() {this.className = "OD3_GTFS_Level"}

  constructor(data) {
    super("OD3_GTFS_Level", data );
  }

  get level_index() {
    return super.get("level_index");
  }
  set level_index(value) {
    super.set("level_index", value);
  }
  get level_name() {
    return super.get("level_name");
  }
  set level_name(value) {
    super.set("level_name", value);
  }
} OD3_GTFS_Level.__initStatic();

Parse.Object.registerSubclass("OD3_GTFS_Level", OD3_GTFS_Level);
