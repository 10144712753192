
import OrderOverviewComponent from "./components/OrderOverviewComponent";
import AkkordComponent from "./components/AkkordComponent";
import { $parse, } from "@opendash/plugin-parse";
export class KuennePlugin  {constructor() { KuennePlugin.prototype.__init.call(this); }
  __init() {this.name = "KuennePlugin"}

  async onFactory(factory) {
    await factory.registerTranslationResolver(
      "en",
      "kuenne",
      async () => await import("./translations/en.json")
    );
    await factory.registerTranslationResolver(
      "de",
      "kuenne",
      async () => await import("./translations/de.json")
    );

    factory.registerRoute({
      path: "/orders/*",
      props: {},
      component: async () => ({ default: OrderOverviewComponent }),
    });
    factory.registerRoute({
      path: "/akkord/*",
      props: {},
      component: async () => ({ default: AkkordComponent }),
    });

    factory.registerStaticNavigationGroup({
      label: "parse-custom:akkord.label",
      order: 15,
      id: "kuenne/akkord",
    });
    factory.registerStaticNavigationItem({
      id: "kuenne/akkordSettings",
      group: "kuenne/akkord",
      place: "sidebar",
      order: 10,
      label: "parse-custom:classes.Kuenne_AkkordMaschinenParameter.label",
      icon: "fa:clock",
      color: "#00599b",
      link: "/admin/parse/Kuenne_AkkordMaschinenParameter",
      routeCondition: "/admin/**",
      activeCondition: "/",
    });

    factory.registerStaticNavigationItem({
      id: "kuenne/orders",
      group: "kuenne/orders",
      place: "frontpage",
      order: 10,
      label: "kuenne:order.label",
      icon: "fa:clipboard",
      color: "#00599b",
      link: "/orders",
      routeCondition: "**",
      activeCondition: "/",
    });
    factory.registerStaticNavigationItem({
      id: "kuenne/akkord",
      group: "kuenne/akkord",
      place: "frontpage",
      order: 11,
      label: "Akkord",
      icon: "fa:clock",
      color: "#4068bf",
      link: "/akkord",
      routeCondition: "**",
      activeCondition: "/",
    });
    $parse.ui.setClassConfig({
      className: "Kuenne_AkkordEintraege",
      translationPrefix: "parse-custom:classes.",
      titleFields: ["werker", "auftrag"],
      displayFields: [
        "werker",
        "monat",
        "jahr",
        "auftrag",
        "maschine",
        "durchmesser",
        "geschwindigkeit",
        "gewicht",
        "zaehlerStart",
        "zaehlerEnde",
      ],
      createFields: [
        "werker",
        "datum",
        "durchmesser",
        "geschwindigkeit",
        "gewicht",
        "zaehlerStart",
        "zaehlerEnde",
        "maschine",
        "auftrag",
      ],
      editFields: [
        "werker",
        "datum",
        "durchmesser",
        "geschwindigkeit",
        "gewicht",
        "zaehlerStart",
        "zaehlerEnde",
        "maschine",
        "auftrag",
      ],
      defaultACL: () => {
        return {
          "role:Akkord-Admin": {
            read: true,
            write: true,
          },
        };
      },
    });
    $parse.ui.setDefaultView("Kuenne_AkkordEintraege", { type: "table" });

    $parse.ui.setClassConfig({
      className: "Kuenne_QATest",
      titleFields: ["AUFNR"],
      displayFields: ["AUFNR", "POSNR", "NAME", "MASNR"],
      createFields: [],
      editFields: [],
      translationPrefix: "parse-custom:classes.",
      defaultACL: () => {
        return {
          "role:Akkord-Admin": {
            read: true,
            write: true,
          },
        };
      },
    });
    $parse.ui.setDefaultView("Kuenne_QATest", { type: "table" });

    $parse.ui.setClassConfig({
      className: "Kuenne_AkkordMaschinenParameter",
      titleFields: ["maschine"],
      displayFields: [
        "maschine",
        "wechselzeit",
        "schwierigkeitsfaktor",
        "basis",
        "maschinenzusatz",
        "updatedAt",
      ],
      createFields: [
        "maschine",
        "wechselzeit",
        "schwierigkeitsfaktor",
        "basis",
        "maschinenzusatz",
      ],
      editFields: [
        "maschine",
        "wechselzeit",
        "schwierigkeitsfaktor",
        "basis",
        "maschinenzusatz",
      ],
      translationPrefix: "parse-custom:classes.",
      defaultACL: () => {
        return {
          "role:Akkord-Admin": {
            read: true,
            write: true,
          },
        };
      },
    });
    $parse.ui.setDefaultView("Kuenne_AkkordMaschinenParameter", {
      type: "table",
    });
  }
}
