const _jsxFileName = "C:\\Users\\MartinStein\\Documents\\Development\\open.DASHV3\\opendash-instance-kuenne\\src\\js\\plugin\\components\\OrderOverviewComponent.tsx"; function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }import {

  useOpenDashServices,
  useTranslation,
} from "@opendash/core";
import dayjs from "dayjs";
import * as React from "react";
import { useDashboards } from "@opendash/plugin-monitoring";
import { Kuenne_QATest, } from "../types";
import Parse, { } from "parse";
import { useNavigate } from "react-router-dom";
import {
  Avatar,
  Button,
  Col,
  Descriptions,
  Divider,
  Drawer,
  Empty,
  List,
  Row,
  Spin,
  Table,
  Tabs,
  Tag,
} from "antd";
import { AdminToolbar, } from "@opendash/ui";
import { useDebounce } from "@opendash/core";
import { useDataService } from "@opendash/plugin-timeseries";
import DescriptionsItem from "antd/es/descriptions/Item";
import { Icon } from "@opendash/icons";
import { stringToColor } from "@opendash/core";
export default () => {
  const t = useTranslation();
  const DataService = useDataService();
  const [orders, setOrders] = React.useState([]);
  const [allOrders, setAllOrders] = React.useState([]);
  const [searchString, setSearchString] = React.useState("");
  const searchStringDebounced = useDebounce(searchString, 1000);
  const dashboards = useDashboards();
  const { SourceService } = useOpenDashServices();
  const sources = SourceService.getAll();
  const [cOrder, setCOrder] = React.useState(null);
  const [successorsStart, setSuccessorsStart] = React.useState

({});
  const [orderOnMachine, setOrderOnMachine] = React.useState

({});
  const overViewBlacklist = [
    "AENDAT",
    "AENUHR",
    "BENID",
    "DATUM",
    "NEUBEN",
    "NEUDAT",
    "NEUUHR",
    "createdAt",
    "updatedAt",
  ];

  const blacklistPruef = ["PH"];
  const [orderRing, setOrderRings] = React.useState


(null);
  const [dataDrawer, setDataDrawer] = React.useState




(null);
  const [availableRings, setAvailableRings] = React.useState(
    []
  );

  const navigate = useNavigate();

  React.useEffect(() => {
    init();
  }, [searchStringDebounced]);

  React.useEffect(() => {
    if (!allOrders) return;
    //Rings
    const rings = {} 


;
    allOrders.forEach((order) => {
      const key = "" + order.AUFNR + "-" + order.POSNR;
      if (!(key in rings)) {
        rings[key] = [
          {
            ring: order.RINGNR || "",
            neu: order.NEUDATETIME,
            aen: order.AENDATETIME,
          },
        ];
      } else {
        rings[key].push({
          ring: order.RINGNR || "",
          neu: order.NEUDATETIME,
          aen: order.AENDATETIME,
        });
      }
    });
    Object.keys(rings).forEach((key) => {
      rings[key] = rings[key].sort((a, b) => {
        return a.neu.getTime() - b.neu.getTime();
      });
    });
    setOrderRings(rings);

    // Machines
    const machineOrders = {};
    for (let cOrder of allOrders) {
      if (!machineOrders["MASNR:" + cOrder.MASNR]) {
        machineOrders["MASNR:" + cOrder.MASNR] = [] ;
      }
      machineOrders["MASNR:" + cOrder.MASNR].push(cOrder);
    }
    Object.keys(machineOrders).forEach((key) => {
      machineOrders[key] = machineOrders[key].sort((a, b) => {
        return a.AENDATETIME.getTime() - b.AENDATETIME.getTime();
      });
    });
    setOrderOnMachine(machineOrders);
  }, [allOrders]);

  const init = async () => {
    let query = new Parse.Query(Kuenne_QATest);
    if (searchString !== "") {
      query.contains("AUFNR", searchString);
      query = Parse.Query.or(
        query,
        new Parse.Query(Kuenne_QATest).equalTo("AUFNR", searchString),
        new Parse.Query(Kuenne_QATest).contains("NAME", searchString),
        new Parse.Query(Kuenne_QATest).contains("BEZ", searchString)
      );
    }
    query.descending("NEUDATETIME");
    query = query.limit(1000);
    const ordersResult = await query.find();

    setAllOrders(ordersResult);
    const reverseAll = [...ordersResult].reverse();
    const currentOrders = {} ;

    for (let i = 0; i < reverseAll.length; i++) {
      const order = reverseAll[i];
      if (
        !currentOrders[order.MASNR + "-" + order.AUFNR + "-" + order.POSNR] ||
        currentOrders[
          order.MASNR + "-" + order.AUFNR + "-" + order.POSNR
        ].AENDATETIME.getTime() < order.AENDATETIME.getTime()
      ) {
        currentOrders[order.MASNR + "-" + order.AUFNR + "-" + order.POSNR] =
          order;
      }
    }

    // setOrders(
    //   ordersResult.filter((order, index, all) => {
    //     return (
    //       index === 0 ||
    //       !(
    //         order.AUFNR === all[index - 1].AUFNR &&
    //         order.POSNR === all[index - 1].POSNR
    //       )
    //     );
    //   })
    // );
    setOrders(
      Object.values(currentOrders).sort((a, b) => {
        return -1 * (a.AENDATETIME.getTime() - b.AENDATETIME.getTime());
      })
    );
  };
  React.useEffect(() => {
    if (orders && orders.length > 0) {
      getOrderEnds(orders);
    }
  }, [orders]);
  const getOrderEnds = async (orders) => {
    const ends = {} ;

    const queries = orders.map((order) => {
      const querfollowingQuery1 = new Parse.Query(Kuenne_QATest).notEqualTo(
        "AUFNR",
        order.AUFNR 
      );

      const followingQuery2 = new Parse.Query(Kuenne_QATest).notEqualTo(
        "POSNR",
        order.POSNR 
      );
      const followingOrder = Parse.Query.or(
        querfollowingQuery1,
        followingQuery2
      )
        .equalTo("MASNR", order.MASNR)
        .greaterThan("NEUDATETIME", order.NEUDATETIME)
        .addAscending("NEUDATETIME");
      return followingOrder.first();

      /*
      return {
        method: "POST",
        path: "/parse/classes/Kuenne_QATest",
        body: followingOrder.toJSON(),
      };
      */
    });

    const res = await Promise.all(queries);

    for (const i in orders) {
      ends[orders[i].id] = _optionalChain([res, 'access', _ => _[i], 'optionalAccess', _2 => _2.NEUDATETIME]) || new Date(0);
    }
    setSuccessorsStart(ends);
  };

  const switchToDashboardForOrder = async (
    dashboard,
    order
  ) => {
    if (
      orderRing == null ||
      orderRing[order.AUFNR + "-" + order.POSNR].length === 0
    )
      return;
    /*
      const querfollowingQuery1 = new Parse.Query(Kuenne_QATest).notEqualTo(
      "AUFNR",
      order.AUFNR as string
    );

    const followingQuery2 = new Parse.Query(Kuenne_QATest).notEqualTo(
      "POSNR",
      order.POSNR as number
    );
    const followingOrder = await Parse.Query.or(
      querfollowingQuery1,
      followingQuery2
    )
      .equalTo("MASNR", order.MASNR)
      .greaterThan("NEUDATETIME", order.NEUDATETIME)
      .addAscending("NEUDATETIME")
      .first();
*/
    const followingOrder =
      successorsStart[order.id].getTime() === 0
        ? new Date()
        : successorsStart[order.id];

    // DataService.store.update((draft) => {
    //   const endTime = !followingOrder
    //     ? new Date().getTime()
    //     : followingOrder.getTime();

    //   draft.overwriteFetchingOptions = {
    //     historyType: "absolute",
    //     start: orderRing[order.AUFNR + "-" + order.POSNR][0].neu.getTime(),
    //     end: endTime,
    //     live: successorsStart[order.id].getTime() === 0,
    //   };
    // });
    SourceService.setCurrent(dashboard.source);
    setTimeout(() => {
      const endTime = !followingOrder
        ? new Date().getTime()
        : followingOrder.getTime();
      const options = {
        historyType: "absolute",
        start: orderRing[order.AUFNR + "-" + order.POSNR][0].neu.getTime(),
        end: endTime,
        live: successorsStart[order.id].getTime() === 0,
      };
      const optionsString = JSON.stringify(options);
      window.open(
        `/monitoring/dashboards/${dashboard.source}/${dashboard.id}?timeOverwrite=${optionsString}`,
        "_blank"
      );
    }, 200);
  };

  const columnItems = [
    "AUFNR",
    "POSNR",
    "MASNR",
    "NAME",
    {
      title: t("kuenne:order.ENDTIME"),
      dataIndex: "endtime",
      key: "endtime",
      render: (text, record, index) => {
        if (!successorsStart[record.id]) return React.createElement(Spin, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 298}} );
        return (
          React.createElement(React.Fragment, null
            , successorsStart[record.id].getTime() !== 0 && (
              React.createElement('p', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 302}}
                , dayjs(successorsStart[record.id].getTime()).format(
                  "HH:mm:ss @ DD.MM.YYYY"
                )
              )
            )
            , successorsStart[record.id].getTime() === 0 && (
              React.createElement(Tag, { icon: React.createElement(Icon, { icon: "fa:play", __self: this, __source: {fileName: _jsxFileName, lineNumber: 309}}), __self: this, __source: {fileName: _jsxFileName, lineNumber: 309}}
                , t("kuenne:order.running")
              )
            )
          )
        );
      },
    },
    {
      title: t("kuenne:order.NEUDATETIME"),
      dataIndex: "NEUDATETIME",
      key: "NEUDATETIME",
      render: (text, record, index) => {
        const neu =
          _optionalChain([orderRing, 'optionalAccess', _3 => _3["" + record.AUFNR + "-" + record.POSNR], 'optionalAccess', _4 => _4[0], 'access', _5 => _5.neu]) ||
          record.NEUDATETIME;
        return dayjs(neu).format("HH:mm:ss @ DD.MM.YYYY");
      },
    },
  ] ;
  const columns = columnItems.map((item) => {
    if ((item ) instanceof Object) return item;
    const translationstring = "kuenne:order." + item;
    return {
      title: t(translationstring),
      dataIndex: item,
      key: item,
    };
  });

  const mapMachine = (maschine) => {
    if (maschine === "mstWork") return maschine;
    return "kuenne_werk1_M" + maschine;
    //return "mstWork";
  };
  return (
    React.createElement('div', { style: { margin: 16 }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 345}}
      , React.createElement(AdminToolbar, {
        title: t("kuenne:order.label"),
        search: "",
        onSearch: (e) => {
          setSearchString(e);
        }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 346}}
)
      , React.createElement('div', {
        style: {
          backgroundColor: "#fff",
          display: "flex",
          flexDirection: "column",
          padding: "16px",
        }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 353}}

        , React.createElement(Row, { style: { width: "100%" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 361}}
          , React.createElement(Col, { span: 24, __self: this, __source: {fileName: _jsxFileName, lineNumber: 362}}
            , React.createElement(Table, {
              columns: 
                columns 
              ,
              dataSource: orders,
              onRow: (record, rowIndex) => {
                return {
                  onClick: (event) => {
                    setCOrder(record);
                  }, // click row
                  onDoubleClick: (event) => {}, // double click row
                  onContextMenu: (event) => {}, // right button click row
                  onMouseEnter: (event) => {}, // mouse enter row
                  onMouseLeave: (event) => {}, // mouse leave row
                };
              }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 363}}
)
          )
        )
        , React.createElement(Divider, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 382}} )
        , cOrder && (
          React.createElement(Row, { style: { paddingLeft: 16, paddingRight: 16 }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 384}}
            , React.createElement(Col, { span: 11, md: 11, xs: 24, __self: this, __source: {fileName: _jsxFileName, lineNumber: 385}}
              , React.createElement(Descriptions, {
                title: t("kuenne:order.overview"),
                bordered: true,
                column: { xxl: 2, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 386}}

                , Object.keys(cOrder.attributes)
                  .sort()
                  .map((key) => {
                    if (overViewBlacklist.indexOf(key) !== -1) return React.createElement(React.Fragment, null);
                    if (key === "RINGNR") {
                      return (
                        React.createElement(DescriptionsItem, {
                          label: t("kuenne:order." + key),
                          span: 1, __self: this, __source: {fileName: _jsxFileName, lineNumber: 397}}

                          , orderRing &&
                            orderRing[cOrder.AUFNR + "-" + cOrder.POSNR] &&
                            orderRing[cOrder.AUFNR + "-" + cOrder.POSNR]
                              .sort((a, b) => {
                                return (
                                  parseInt(_optionalChain([a, 'access', _6 => _6.ring, 'optionalAccess', _7 => _7.split, 'call', _8 => _8("-"), 'access', _9 => _9[1]]) || "0") -
                                  parseInt(_optionalChain([b, 'access', _10 => _10.ring, 'optionalAccess', _11 => _11.split, 'call', _12 => _12("-"), 'access', _13 => _13[1]]) || "0")
                                );
                              })
                              .map((order) => {
                                return React.createElement(Tag, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 411}}, order.ring);
                              })
                        )
                      );
                    }
                    return (
                      React.createElement(DescriptionsItem, {
                        label: t("kuenne:order." + key),
                        span: 1, __self: this, __source: {fileName: _jsxFileName, lineNumber: 417}}

                        , 
                          React.createElement(React.Fragment, null
                            , typeof cOrder.get(
                              key 
                            ) === "object" &&
                              !(
                                cOrder.get(
                                  key 
                                ) instanceof Date
                              ) && (
                                React.createElement(Button, {
                                  type: "link",
                                  onClick: () => {
                                    setDataDrawer({
                                      data: cOrder,
                                      datakey: key,
                                      label: key,
                                      blacklist: blacklistPruef,
                                    });
                                  }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 431}}

                                  , t(
                                    "opendash:monitoring.data_sidebar.details"
                                  )
                                )
                              )
                            , cOrder.get(
                              key 
                            ) instanceof Date &&
                              (
                                cOrder[
                                  key 



                                ] 
                              ).toDateString()

                            , !(
                              typeof cOrder.get(
                                key 
                              ) === "object"
                            ) &&
                              !(
                                cOrder.get(
                                  key 
                                ) instanceof Date
                              ) &&
                              cOrder[
                                key 



                              ]
                          )
                        
                      )
                    );
                  })
              )
            )
            , React.createElement(Col, { span: 12, md: 12, xs: 24, style: { paddingLeft: 16 }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 482}}
              , React.createElement('div', { style: { marginBottom: 20 }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 483}}
                , React.createElement('div', {
                  style: {
                    fontWeight: 600,
                    fontSize: " 16px",
                    lineHeight: 1.5,
                  }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 484}}

                  , t("kuenne:order.dashboards")
                )
              )
              , !cOrder.MASNR && (
                React.createElement(Empty, {
                  description: React.createElement('span', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 496}}, t("kuenne:order.noMachine")), __self: this, __source: {fileName: _jsxFileName, lineNumber: 495}}
                )
              )
              , cOrder.MASNR && (
                React.createElement(List, {
                  itemLayout: "horizontal",
                  dataSource: dashboards.filter((dashboard) => {
                    const source = sources.find(
                      (source) => source.id === dashboard.source
                    );
                    return mapMachine(cOrder.MASNR) === _optionalChain([source, 'optionalAccess', _14 => _14.tag]);
                  }),
                  renderItem: (dashboard) => (
                    React.createElement(List.Item, {
                      onClick: () => {
                        switchToDashboardForOrder(dashboard, cOrder);
                      },
                      actions: [
                        React.createElement('a', {
                          key: "link" + dashboard.id,
                          onClick: () => {
                            switchToDashboardForOrder(dashboard, cOrder);
                          }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 514}}

                          , React.createElement(Icon, { icon: "fa:chevron-right", __self: this, __source: {fileName: _jsxFileName, lineNumber: 520}} )
                        ),
                      ], __self: this, __source: {fileName: _jsxFileName, lineNumber: 509}}

                      , React.createElement(List.Item.Meta, {
                        avatar: 
                          React.createElement(Avatar, {
                            style: {
                              backgroundColor: stringToColor(dashboard.name),
                            }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 526}}

                            , dashboard.name.substring(0, 2).toLocaleUpperCase()
                          )
                        ,
                        title: dashboard.name,
                        description: 
                          _optionalChain([sources, 'access', _15 => _15.find, 'call', _16 => _16(
                            (cSource) => cSource.id === dashboard.source
                          ), 'optionalAccess', _17 => _17.name]) || "?"
                        , __self: this, __source: {fileName: _jsxFileName, lineNumber: 524}}
                      )
                    )
                  ), __self: this, __source: {fileName: _jsxFileName, lineNumber: 500}}
                )
              )
            )

            , !!dataDrawer && (
              React.createElement(Drawer, {
                onClose: () => setDataDrawer(null),
                placement: "bottom",
                open: !!dataDrawer,
                title: t("kuenne:order." + dataDrawer.label),
                height: "60%", __self: this, __source: {fileName: _jsxFileName, lineNumber: 548}}

                , React.createElement(Tabs, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 555}}
                  , allOrders
                    .filter(
                      (order) =>
                        order.AUFNR === dataDrawer.data.AUFNR &&
                        order.POSNR === dataDrawer.data.POSNR
                    )
                    .sort((a, b) => {
                      return (
                        parseInt(_optionalChain([a, 'access', _18 => _18.RINGNR, 'optionalAccess', _19 => _19.split, 'call', _20 => _20("-"), 'access', _21 => _21[1]]) || "0") -
                        parseInt(_optionalChain([b, 'access', _22 => _22.RINGNR, 'optionalAccess', _23 => _23.split, 'call', _24 => _24("-"), 'access', _25 => _25[1]]) || "0")
                      );
                    })
                    .map((order) => {
                      return (
                        React.createElement(Tabs.TabPane, { tab: order.RINGNR, key: order.RINGNR, __self: this, __source: {fileName: _jsxFileName, lineNumber: 570}}
                          , React.createElement(Descriptions, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 571}}
                            , Object.keys(
                              order[dataDrawer.datakey ]
                            )
                              .sort()
                              .map((key) => {
                                const isBlacklisted = dataDrawer.blacklist.find(
                                  (blackKey) => {
                                    return key.startsWith(blackKey);
                                  }
                                );
                                if (isBlacklisted) return null;
                                let label = t(
                                  `kuenne:order.KEYS_${dataDrawer.datakey}.${key}`
                                );

                                if (dataDrawer.datakey === "CHARGE") {
                                  if (key.indexOf("CHGTX") !== -1) {
                                    return null;
                                  }
                                  if (key.indexOf("CHGWE") > -1) {
                                    const newKey = key.replace(
                                      "CHGWE",
                                      "CHGTX"
                                    );

                                    label =
                                      order[dataDrawer.datakey][newKey ];
                                  }
                                }

                                return (
                                  React.createElement(DescriptionsItem, { label: label, __self: this, __source: {fileName: _jsxFileName, lineNumber: 603}}
                                    , 
                                      order[
                                        dataDrawer.datakey 
                                      ][key]
                                    
                                  )
                                );
                              })
                          )
                        )
                      );
                    })
                )
                /* <Descriptions>
                {Object.keys(dataDrawer.data)
                  .sort()
                  .map((key) => {
                    return (
                      <DescriptionsItem
                        label={t(`kuenne:order.${dataDrawer.label}.${key}`)}
                      >
                        {dataDrawer.data[key]}
                      </DescriptionsItem>
                    );
                  })}
              </Descriptions> */
              )
            )
          )
        )
      )
    )
  );
};
