import Parse from "parse";












export class OD3_TenantMeta extends Parse.Object {
  static __initStatic() {this.className = "OD3_TenantMeta"}

  constructor(data) {
    super("OD3_TenantMeta", data );
  }

  get tenant() {
    return super.get("tenant");
  }
  set tenant(value) {
    super.set("tenant", value);
  }
} OD3_TenantMeta.__initStatic();

Parse.Object.registerSubclass("OD3_TenantMeta", OD3_TenantMeta);
