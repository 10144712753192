import Parse from "parse";


















export class OD3_Maintenance_Kanban_State extends Parse.Object {
  static __initStatic() {this.className = "OD3_Maintenance_Kanban_State"}

  constructor(data) {
    super("OD3_Maintenance_Kanban_State", data );
  }

  get color() {
    return super.get("color");
  }
  set color(value) {
    super.set("color", value);
  }
  get description() {
    return super.get("description");
  }
  set description(value) {
    super.set("description", value);
  }
  get isFinished() {
    return super.get("isFinished");
  }
  set isFinished(value) {
    super.set("isFinished", value);
  }
  get isInbox() {
    return super.get("isInbox");
  }
  set isInbox(value) {
    super.set("isInbox", value);
  }
  get label() {
    return super.get("label");
  }
  set label(value) {
    super.set("label", value);
  }
  get order() {
    return super.get("order");
  }
  set order(value) {
    super.set("order", value);
  }
  get tenant() {
    return super.get("tenant");
  }
  set tenant(value) {
    super.set("tenant", value);
  }
} OD3_Maintenance_Kanban_State.__initStatic();

Parse.Object.registerSubclass("OD3_Maintenance_Kanban_State", OD3_Maintenance_Kanban_State);
