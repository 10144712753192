import Parse from "parse";





















export class OD3_GTFS_Route extends Parse.Object {
  static __initStatic() {this.className = "OD3_GTFS_Route"}

  constructor(data) {
    super("OD3_GTFS_Route", data );
  }

  get agency_id() {
    return super.get("agency_id");
  }
  set agency_id(value) {
    super.set("agency_id", value);
  }
  get route_color() {
    return super.get("route_color");
  }
  set route_color(value) {
    super.set("route_color", value);
  }
  get route_desc() {
    return super.get("route_desc");
  }
  set route_desc(value) {
    super.set("route_desc", value);
  }
  get route_id() {
    return super.get("route_id");
  }
  set route_id(value) {
    super.set("route_id", value);
  }
  get route_long_name() {
    return super.get("route_long_name");
  }
  set route_long_name(value) {
    super.set("route_long_name", value);
  }
  get route_short_name() {
    return super.get("route_short_name");
  }
  set route_short_name(value) {
    super.set("route_short_name", value);
  }
  get route_sort_order() {
    return super.get("route_sort_order");
  }
  set route_sort_order(value) {
    super.set("route_sort_order", value);
  }
  get route_text_color() {
    return super.get("route_text_color");
  }
  set route_text_color(value) {
    super.set("route_text_color", value);
  }
  get route_type() {
    return super.get("route_type");
  }
  set route_type(value) {
    super.set("route_type", value);
  }
  get route_url() {
    return super.get("route_url");
  }
  set route_url(value) {
    super.set("route_url", value);
  }
} OD3_GTFS_Route.__initStatic();

Parse.Object.registerSubclass("OD3_GTFS_Route", OD3_GTFS_Route);
