import Parse from "parse";












export class OD3_BDE_Result extends Parse.Object {
  static __initStatic() {this.className = "OD3_BDE_Result"}

  constructor(data) {
    super("OD3_BDE_Result", data );
  }

  get result() {
    return super.get("result");
  }
  set result(value) {
    super.set("result", value);
  }
  get RID() {
    return super.get("RID");
  }
  set RID(value) {
    super.set("RID", value);
  }
  get rueckmeldeUnit() {
    return super.get("rueckmeldeUnit");
  }
  set rueckmeldeUnit(value) {
    super.set("rueckmeldeUnit", value);
  }
} OD3_BDE_Result.__initStatic();

Parse.Object.registerSubclass("OD3_BDE_Result", OD3_BDE_Result);
