import Parse from "parse";
















export class Kuenne_AkkordMaschinenParameter extends Parse.Object {
  static __initStatic() {this.className = "Kuenne_AkkordMaschinenParameter"}

  constructor(data) {
    super("Kuenne_AkkordMaschinenParameter", data );
  }

  get basis() {
    return super.get("basis");
  }
  set basis(value) {
    super.set("basis", value);
  }
  get maschine() {
    return super.get("maschine");
  }
  set maschine(value) {
    super.set("maschine", value);
  }
  get maschinenzusatz() {
    return super.get("maschinenzusatz");
  }
  set maschinenzusatz(value) {
    super.set("maschinenzusatz", value);
  }
  get schwierigkeitsfaktor() {
    return super.get("schwierigkeitsfaktor");
  }
  set schwierigkeitsfaktor(value) {
    super.set("schwierigkeitsfaktor", value);
  }
  get wechselzeit() {
    return super.get("wechselzeit");
  }
  set wechselzeit(value) {
    super.set("wechselzeit", value);
  }
} Kuenne_AkkordMaschinenParameter.__initStatic();

Parse.Object.registerSubclass("Kuenne_AkkordMaschinenParameter", Kuenne_AkkordMaschinenParameter);
