import Parse from "parse";



























export class OD3_GTFS_Stop extends Parse.Object {
  static __initStatic() {this.className = "OD3_GTFS_Stop"}

  constructor(data) {
    super("OD3_GTFS_Stop", data );
  }

  get level_id() {
    return super.get("level_id");
  }
  set level_id(value) {
    super.set("level_id", value);
  }
  get location_type() {
    return super.get("location_type");
  }
  set location_type(value) {
    super.set("location_type", value);
  }
  get parent_station() {
    return super.get("parent_station");
  }
  set parent_station(value) {
    super.set("parent_station", value);
  }
  get platform_code() {
    return super.get("platform_code");
  }
  set platform_code(value) {
    super.set("platform_code", value);
  }
  get stop_code() {
    return super.get("stop_code");
  }
  set stop_code(value) {
    super.set("stop_code", value);
  }
  get stop_desc() {
    return super.get("stop_desc");
  }
  set stop_desc(value) {
    super.set("stop_desc", value);
  }
  get stop_id() {
    return super.get("stop_id");
  }
  set stop_id(value) {
    super.set("stop_id", value);
  }
  get stop_lat() {
    return super.get("stop_lat");
  }
  set stop_lat(value) {
    super.set("stop_lat", value);
  }
  get stop_lon() {
    return super.get("stop_lon");
  }
  set stop_lon(value) {
    super.set("stop_lon", value);
  }
  get stop_name() {
    return super.get("stop_name");
  }
  set stop_name(value) {
    super.set("stop_name", value);
  }
  get stop_timezone() {
    return super.get("stop_timezone");
  }
  set stop_timezone(value) {
    super.set("stop_timezone", value);
  }
  get stop_url() {
    return super.get("stop_url");
  }
  set stop_url(value) {
    super.set("stop_url", value);
  }
  get wheelchair_boarding() {
    return super.get("wheelchair_boarding");
  }
  set wheelchair_boarding(value) {
    super.set("wheelchair_boarding", value);
  }
  get zone_id() {
    return super.get("zone_id");
  }
  set zone_id(value) {
    super.set("zone_id", value);
  }
} OD3_GTFS_Stop.__initStatic();

Parse.Object.registerSubclass("OD3_GTFS_Stop", OD3_GTFS_Stop);
