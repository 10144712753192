export class AkkordBerechnungErich {
  
  
  
  
  
  
  
  

  constructor(
    dichte,
    durchmesser,
    geschwindigkeit,
    gewicht,
    produzierteMeter,
    wechselzeit,
    schwierigkeit,
    basis
  ) {
    this.dichte = dichte;
    this.durchmesser = durchmesser;
    this.geschwindigkeit = geschwindigkeit;
    this.gewicht = gewicht;
    this.produzierteMeter = produzierteMeter;
    this.wechselzeit = wechselzeit;
    this.schwierigkeit = schwierigkeit;
    this.basis = basis;
  }

  get stundenleistung() {
    return (
      ((Math.pow(this.durchmesser / 1000, 2) * Math.PI) / 4) *
      this.dichte *
      3600 *
      this.geschwindigkeit
    );
  }
  get gewichtProMeter() {
    return ((Math.pow(this.durchmesser / 1000, 2) * Math.PI) / 4) * this.dichte;
  }
  get gebindeProStunde() {
    return this.stundenleistung / this.gewicht;
  }
  get test() {
    return (this.gewicht / this.stundenleistung) * 60 + this.wechselzeit;
  }
  get theoretischeStundeLeistung() {
    return 60 / ((this.gewicht / this.stundenleistung) * 60 + this.wechselzeit);
  }
  get unproduktiverZeitnteilAbs() {
    return this.gebindeProStunde - this.theoretischeStundeLeistung;
  }
  get unproduktiverZeitnteilRel() {
    return this.unproduktiverZeitnteilAbs / this.gebindeProStunde;
  }
  get produktiverZeitnteilRel() {
    return 1 - this.unproduktiverZeitnteilRel;
  }
  get anteilSchwierigkeitsfaktor() {
    return (100 - this.schwierigkeit) / 100;
  }
  get produktiveStundenleistung() {
    return this.produktiverZeitnteilRel * this.stundenleistung;
  }
  get produktiveStundenleistungMitFaktor() {
    return this.produktiveStundenleistung / this.anteilSchwierigkeitsfaktor;
  }
  get preisJeKilo() {
    return (100 / this.produktiveStundenleistungMitFaktor) * this.basis;
  }
  get preisGesamt() {
    return this.gewichtProMeter * this.produzierteMeter * this.preisJeKilo;
  }
  get produziertesGewicht() {
    return this.gewichtProMeter * this.produzierteMeter;
  }
}
