const _jsxFileName = "C:\\Users\\MartinStein\\Documents\\Development\\open.DASHV3\\opendash-instance-kuenne\\src\\js\\plugin\\components\\AkkordComponent.tsx"; function _nullishCoalesce(lhs, rhsFn) { if (lhs != null) { return lhs; } else { return rhsFn(); } } function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }import { produce, useOpenDashServices } from "@opendash/core";
import { Icon } from "@opendash/icons";
import { $parse, ParseFilterState, ParseSetView } from "@opendash/plugin-parse";
import {
  Button,
  DatePicker,
  Divider,
  InputNumber,
  notification,
  Select,
  Tag,
  Typography,
} from "antd";
import dayjs, { } from "dayjs";
import Parse from "parse";
import React from "react";
import { useAkkordMetaData } from "../hooks/useAkkordMetaData";
import { OD3_Source } from "../types";

import {
  Kuenne_AkkordEintraege,

} from "../types/Kuenne_AkkordEintraege";
import AkkordResultDrawer from "./AkkordResultDrawer";


const classname = "Kuenne_AkkordEintraege";

const AkkordComponent = (props) => {
  const styleOfForm = { minWidth: 100 };
  const { SourceService } = useOpenDashServices();
  const [date, setDate] = React.useState(undefined);
  const [worker, setWorker] = React.useState(
    undefined
  );
  const [resultopen, setResultopen] = React.useState(false);

  const [newEntry, setNewEntry] = React.useState

({ datum: _optionalChain([date, 'optionalAccess', _ => _.toDate, 'call', _2 => _2()]) || undefined });

  const { sources, users, machines, orders } = useAkkordMetaData(newEntry);
  const allMachines = SourceService.getAll().filter((cSource) => !!cSource.tag);
  const view = $parse.ui.defaultViewMap[classname];
  const [reloadTable, setReloadTable] = React.useState(0);
  const state = React.useMemo(() => {
    const newState = new ParseFilterState(classname, view, false);
    newState.setPageSize(1000);
    newState.setCustomQuery((query) => {
      query.limit(1000);
      query.descending("datum");
      if (date) {
        query.greaterThanOrEqualTo("datum", date.startOf("month").toDate());
        query.lessThanOrEqualTo("datum", date.endOf("month").toDate());
      }
      if (worker) {
        query.equalTo("werker", worker);
      }
    });

    return newState;
  }, [date, worker, reloadTable]);

  const saveEntry = () => {
    const newEntryInstance = new Kuenne_AkkordEintraege(newEntry);
    newEntryInstance.setACL(
      new Parse.ACL({ "role:Akkord-Admin": { read: true, write: true } })
    );
    newEntryInstance.set("monat", newEntry.datum.getMonth() + 1);
    newEntryInstance.set("jahr", newEntry.datum.getFullYear());
    newEntryInstance
      .save()
      .then((res) => {
        setNewEntry({
          ...newEntry,
          //@ts-ignore
          werker: newEntry.werker || worker,
          datum: _optionalChain([date, 'optionalAccess', _3 => _3.toDate, 'call', _4 => _4()]),
          zaehlerStart: newEntry.zaehlerEnde,
          zaehlerEnde: undefined,
        });
        notification.success({
          message: "Eintrag erfolgreich gespeichert",
          description: `${_optionalChain([res, 'access', _5 => _5.get, 'call', _6 => _6("werker"), 'optionalAccess', _7 => _7.getUsername, 'call', _8 => _8()])} - ${
            newEntry.datum.getMonth() + 1
          }/${newEntry.datum.getFullYear()} - ${
            _optionalChain([newEntry, 'access', _9 => _9.auftrag, 'optionalAccess', _10 => _10.AUFNR]) || "Ohne Auftrag"
          }`,
        });
        setReloadTable((draft) => draft + 1);
      })
      .catch((error) => {
        notification.error({
          message: "Fehler beim Speichern",
          description: error.message,
        });
      });
  };

  React.useEffect(() => {
    if (_optionalChain([newEntry, 'access', _11 => _11.auftrag, 'optionalAccess', _12 => _12.AUFNR])) {
      setNewEntry(
        produce((draft) => {
          draft.maschine = machines[0];

          if (_optionalChain([newEntry, 'access', _13 => _13.auftrag, 'optionalAccess', _14 => _14.PRUEFERGEBNIS, 'optionalAccess', _15 => _15.DURCHM])) {
            draft.durchmesser = _optionalChain([newEntry, 'access', _16 => _16.auftrag, 'optionalAccess', _17 => _17.PRUEFERGEBNIS, 'optionalAccess', _18 => _18.DURCHM]);
          }
        })
      );
    }
  }, [_optionalChain([newEntry, 'access', _19 => _19.auftrag, 'optionalAccess', _20 => _20.AUFNR])]);

  // React.useEffect(() => {
  //   if (newEntry.auftrag?.AUFNR && newEntry.maschine?.id) {
  //     setNewEntry(
  //       produce((draft) => {
  //         draft.maschine = machines[0];
  //         if (newEntry.auftrag?.PRUEFERGEBNIS?.DURCHM) {
  //           draft.durchmesser = newEntry.auftrag?.PRUEFERGEBNIS?.DURCHM;
  //         }
  //       })
  //     );
  //   }
  // }, [newEntry.maschine?.id]);

  React.useEffect(() => {
    if (!newEntry.datum && date) {
      setNewEntry(
        produce((draft) => {
          draft.datum = _optionalChain([date, 'optionalAccess', _21 => _21.toDate, 'call', _22 => _22()]);
        })
      );
    }
    if (!newEntry.werker && worker) {
      setNewEntry(
        produce((draft) => {
          //@ts-ignore
          draft.werker = worker;
        })
      );
    }
  }, [date, worker]);
  const newEntryCompoent = (
    React.createElement(React.Fragment, null
      , React.createElement(Divider, { orientation: "left", __self: this, __source: {fileName: _jsxFileName, lineNumber: 146}}
        , React.createElement(Typography.Text, { type: "secondary", __self: this, __source: {fileName: _jsxFileName, lineNumber: 147}}, "Neuer Eintrag" ), " "
      )
      , React.createElement('div', {
        style: {
          width: "100%",
          minWidth: "1200",
          overflowX: "auto",

          display: "flex",
          flexDirection: "row",
          gap: "2px",
        }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 149}}

        , React.createElement(Select, {
          style: { ...styleOfForm, flexGrow: 1 },
          showSearch: true,
          placeholder: "Werker",
          value: _optionalChain([newEntry, 'access', _23 => _23.werker, 'optionalAccess', _24 => _24.id]) || null,
          filterOption: (input, option) => {
            const res =
              _optionalChain([option, 'optionalAccess', _25 => _25.label
, 'optionalAccess', _26 => _26.toLocaleLowerCase, 'call', _27 => _27()
, 'access', _28 => _28.indexOf, 'call', _29 => _29(input.toLocaleLowerCase())]) !== -1;
            return res;
          },
          options: users.map((user) => ({
            label: user.getUsername(),
            value: user.id,
          })),
          onChange: (value) => {
            setNewEntry(
              produce((draft) => {
                //@ts-ignore
                draft.werker = users.find((user) => user.id === value);
              })
            );
          }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 160}}
)
        , React.createElement(DatePicker, {
          style: styleOfForm,
          picker: "month",
          value: date || newEntry.datum ? dayjs(newEntry.datum) : null,
          placeholder: "Monat",
          onChange: (value) => {
            setNewEntry(
              produce((draft) => {
                draft.datum = value ? value.toDate() : undefined;
                delete draft.auftrag;
              })
            );
          }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 185}}
        )
        , React.createElement(Select, {
          placeholder: "Auftrag",
          style: { ...styleOfForm, flexGrow: 1 },
          value: _optionalChain([newEntry, 'access', _30 => _30.auftrag, 'optionalAccess', _31 => _31.id]) || null,
          allowClear: true,
          filterOption: (input, option) => {
            const res =
              _optionalChain([option, 'optionalAccess', _32 => _32.label
, 'optionalAccess', _33 => _33.toLocaleLowerCase, 'call', _34 => _34()
, 'access', _35 => _35.indexOf, 'call', _36 => _36(input.toLocaleLowerCase())]) !== -1;
            return res;
          },
          showSearch: true,
          options: 
            newEntry.datum
              ? orders
                  .filter((order) => {
                    const start = dayjs(_optionalChain([newEntry, 'access', _37 => _37.datum, 'optionalAccess', _38 => _38.getTime, 'call', _39 => _39()]))
                      .startOf("month")
                      .subtract(1, "month")
                      .valueOf();
                    const end = dayjs(_optionalChain([newEntry, 'access', _40 => _40.datum, 'optionalAccess', _41 => _41.getTime, 'call', _42 => _42()]))
                      .endOf("month")
                      .valueOf();
                    return (
                      _optionalChain([order, 'access', _43 => _43.get, 'call', _44 => _44("AENDATETIME"), 'optionalAccess', _45 => _45.getTime, 'call', _46 => _46()]) ||
                      (0 > start && _optionalChain([order, 'access', _47 => _47.get, 'call', _48 => _48("AENDATETIME"), 'optionalAccess', _49 => _49.getTime, 'call', _50 => _50()])) ||
                      0 < end
                    );
                  })
                  .map((order) => ({
                    label: `${order.get("AUFNR")} | ${order.get(
                      "POSNR"
                    )} - ${order.get("NAME")}`,
                    value: order.id,
                  }))
                  .filter(
                    (order, index, cOrders) =>
                      index ===
                      cOrders.findIndex(
                        (cOrder) => cOrder.label === order.label
                      )
                  )
                  .sort((a, b) => a.label.localeCompare(b.label))
              : []
          ,
          onChange: (value) => {
            setNewEntry(
              produce((draft) => {
                draft.auftrag = orders.find((order) => order.id === value);
              })
            );
          }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 199}}
)
        , React.createElement(Select, {
          placeholder: "Maschine",
          style: { ...styleOfForm, flexGrow: 1 },
          value: _optionalChain([newEntry, 'access', _51 => _51.maschine, 'optionalAccess', _52 => _52.id]) || null,
          filterOption: (input, option) => {
            const res =
              _optionalChain([option, 'optionalAccess', _53 => _53.label
, 'optionalAccess', _54 => _54.toLocaleLowerCase, 'call', _55 => _55()
, 'access', _56 => _56.indexOf, 'call', _57 => _57(input.toLocaleLowerCase())]) !== -1;
            return res;
          },
          showSearch: true,
          options: allMachines.map((machine) => ({
            label: machine.name,
            value: machine.id,
          })),
          dropdownRender: (menu) => {
            return (
              React.createElement(React.Fragment, null
                , newEntry.auftrag && (
                  React.createElement(React.Fragment, null
                    , React.createElement(Typography.Text, { type: "secondary", __self: this, __source: {fileName: _jsxFileName, lineNumber: 274}}, "Aus Auftrag"

                    )

                    , React.createElement('div', { style: { padding: "2px" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 278}}
                      , machines.map((cMachine) => (
                        React.createElement(Tag, {
                          onClick: () => {
                            setNewEntry(
                              produce((draft) => {
                                draft.maschine = machines.find(
                                  (m) => m.id === cMachine.id
                                );
                              })
                            );
                          }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 280}}

                          , cMachine.name
                        )
                      ))
                    )

                    , React.createElement(Typography.Text, { type: "secondary", __self: this, __source: {fileName: _jsxFileName, lineNumber: 296}}, "Sonstige Maschinen"

                    )
                  )
                )

                , menu
              )
            );
          },
          onChange: (value) => {
            setNewEntry(
              produce((draft) => {
                const source = allMachines.find((m) => m.id === value);
                if (source) {
                  draft.maschine = new OD3_Source({ id: _optionalChain([source, 'optionalAccess', _58 => _58.id]) });
                }
              })
            );
          }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 253}}
)
        , React.createElement(InputNumber, {
          value: newEntry.durchmesser,
          placeholder: "Durchmesser",
          style: styleOfForm,
          onChange: (value) => {
            setNewEntry(
              produce((draft) => {
                draft.durchmesser = value || undefined;
              })
            );
          }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 317}}
)
        , React.createElement(InputNumber, {
          placeholder: "Geschwindigkeit",
          value: newEntry.geschwindigkeit,
          style: styleOfForm,
          onChange: (value) => {
            setNewEntry(
              produce((draft) => {
                draft.geschwindigkeit = value || undefined;
              })
            );
          }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 329}}
)
        , React.createElement(InputNumber, {
          placeholder: "Gewicht",
          style: styleOfForm,
          value: newEntry.gewicht,
          onChange: (value) => {
            setNewEntry(
              produce((draft) => {
                draft.gewicht = value || undefined;
              })
            );
          }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 341}}
)
        , React.createElement(InputNumber, {
          placeholder: "Zähler Start" ,
          value: newEntry.zaehlerStart,
          style: styleOfForm,
          onChange: (value) => {
            setNewEntry(
              produce((draft) => {
                draft.zaehlerStart = _nullishCoalesce(value, () => ( undefined));
              })
            );
          }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 353}}
)
        , React.createElement(InputNumber, {
          placeholder: "Zähler Ende" ,
          value: newEntry.zaehlerEnde,
          style: styleOfForm,
          onChange: (value) => {
            setNewEntry(
              produce((draft) => {
                draft.zaehlerEnde = _nullishCoalesce(value, () => ( undefined));
              })
            );
          }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 365}}
)
        , React.createElement(Button, {
          type: "primary",
          onClick: saveEntry,
          style: { width: "60px", minWidth: "60px", marginLeft: 16 },
          icon: React.createElement(Icon, { icon: "fa:save", __self: this, __source: {fileName: _jsxFileName, lineNumber: 381}}), __self: this, __source: {fileName: _jsxFileName, lineNumber: 377}}
)
      )
      , React.createElement(Divider, { orientation: "left", style: { marginBottom: -50 }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 384}}
        , React.createElement(Typography.Text, { type: "secondary", __self: this, __source: {fileName: _jsxFileName, lineNumber: 385}}
          , `Vorhandene Einträge ${date ? "im Monat " : ""} ${
            worker ? "nach Werker" : ""
          }`
        )
      )
    )
  );

  return (
    React.createElement('div', {
      style: {
        display: "flex",
        flexDirection: "column",
        background: "#fff",
        margin: 16,
        paddingBottom: 16,
      }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 395}}

      , React.createElement(ParseSetView, {
        className: classname,
        hideCreate: true,
        state: state,
        view: view,
        headerExtra: [
          React.createElement(DatePicker, {
            picker: "month",
            //value={date}
            style: { width: "100%" },
            onChange: (value) => {
              console.log(value);
              setDate(value ? dayjs(value) : undefined);
            }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 410}}
          ),
          React.createElement(Select, {
            style: { width: "100%", marginTop: "8px" },
            showSearch: true,
            allowClear: true,
            placeholder: "Werker",
            value: _optionalChain([worker, 'optionalAccess', _59 => _59.id]) || null,
            filterOption: (input, option) => {
              const res =
                _optionalChain([option, 'optionalAccess', _60 => _60.label
, 'optionalAccess', _61 => _61.toLocaleLowerCase, 'call', _62 => _62()
, 'access', _63 => _63.indexOf, 'call', _64 => _64(input.toLocaleLowerCase())]) !== -1;
              return res;
            },
            options: users.map((user) => ({
              label: user.getUsername(),
              value: user.id,
            })),
            onChange: (value) => {
              //@ts-ignore
              setWorker(users.find((user) => user.id === value) || undefined);
            }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 419}}
),
          newEntryCompoent,
        ],
        onRow: (row, index) => {
          return {
            onClick: (event) => {}, // click row
            onDoubleClick: (event) => {}, // double click row
            onContextMenu: (event) => {}, // right button click row
            onMouseEnter: (event) => {}, // mouse enter row
            onMouseLeave: (event) => {}, // mouse leave row
          };
        }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 404}}
      )
      , React.createElement('div', { style: { width: "100%", padding: "0px 8px" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 453}}
        , React.createElement(Button, {
          type: "primary",
          style: { width: "100%" },
          onClick: () => {
            setResultopen(true);
          }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 454}}
, "Auswertung anzeigen"

        )
      )

      , React.createElement(AkkordResultDrawer, {
        open: resultopen,
        placement: "bottom",
        height: "100%",
        closable: true,
        onClose: () => {
          setResultopen(false);
        },
        worker: worker,
        month: _optionalChain([date, 'optionalAccess', _65 => _65.toDate, 'call', _66 => _66()]) || undefined, __self: this, __source: {fileName: _jsxFileName, lineNumber: 465}}
      )
    )
  );
};

export default AkkordComponent;
