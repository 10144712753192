import Parse from "parse";















export class ReportingHistory extends Parse.Object {
  static __initStatic() {this.className = "ReportingHistory"}

  constructor(data) {
    super("ReportingHistory", data );
  }

  get owner() {
    return super.get("owner");
  }
  set owner(value) {
    super.set("owner", value);
  }
  get params() {
    return super.get("params");
  }
  set params(value) {
    super.set("params", value);
  }
  get source() {
    return super.get("source");
  }
  set source(value) {
    super.set("source", value);
  }
  get type() {
    return super.get("type");
  }
  set type(value) {
    super.set("type", value);
  }
} ReportingHistory.__initStatic();

Parse.Object.registerSubclass("ReportingHistory", ReportingHistory);
