import Parse from "parse";

















export class OD3_GTFS_Stop_Times extends Parse.Object {
  static __initStatic() {this.className = "OD3_GTFS_Stop_Times"}

  constructor(data) {
    super("OD3_GTFS_Stop_Times", data );
  }

  get arrival_time() {
    return super.get("arrival_time");
  }
  set arrival_time(value) {
    super.set("arrival_time", value);
  }
  get departure_time() {
    return super.get("departure_time");
  }
  set departure_time(value) {
    super.set("departure_time", value);
  }
  get stop_id() {
    return super.get("stop_id");
  }
  set stop_id(value) {
    super.set("stop_id", value);
  }
  get stop_sequence() {
    return super.get("stop_sequence");
  }
  set stop_sequence(value) {
    super.set("stop_sequence", value);
  }
  get trip_id() {
    return super.get("trip_id");
  }
  set trip_id(value) {
    super.set("trip_id", value);
  }
} OD3_GTFS_Stop_Times.__initStatic();

Parse.Object.registerSubclass("OD3_GTFS_Stop_Times", OD3_GTFS_Stop_Times);
