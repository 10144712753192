const _jsxFileName = "C:\\Users\\MartinStein\\Documents\\Development\\open.DASHV3\\opendash-instance-kuenne\\src\\js\\plugin\\components\\AkkordResultDrawer.tsx"; function _nullishCoalesce(lhs, rhsFn) { if (lhs != null) { return lhs; } else { return rhsFn(); } } function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }import { Button, Drawer, Typography } from "antd";
import React from "react";

import { useAkkordEntries } from "../hooks/useAkkordEntries";
import { useMachineParameters } from "../hooks/useMachineParameters";
import { AkkordBerechnungErich } from "../classes/AkkordBerechnungErich";

import { Icon } from "@opendash/icons";











const AkkordResultDrawer = (props) => {
  const { result: entries, reload } = useAkkordEntries(
    props.month,
    props.worker
  );
  const { result: machParamter } = useMachineParameters();

  const costEntries = entries.map((entry) => {
    const machParam = machParamter.find(
      (cParam) => cParam.maschine.id === _optionalChain([entry, 'access', _ => _.maschine, 'optionalAccess', _2 => _2.id])
    );
    if (!machParam) {
      return {
        entry: entry,
        error: "Missing Parameters for Machine",
        cost: null,
      };
    }
    return {
      entry: entry,
      cost: new AkkordBerechnungErich(
        entry.dichte,
        entry.durchmesser,
        entry.geschwindigkeit,
        entry.gewicht,
        entry.zaehlerEnde - entry.zaehlerStart,
        machParam.wechselzeit,
        machParam.schwierigkeitsfaktor,
        machParam.basis
      ),
      error: null,
    };
  }) ;
  //@ts-ignore
  const byWorker = Object.groupBy(costEntries, (entry) => {
    return _optionalChain([entry, 'access', _3 => _3.entry, 'access', _4 => _4.werker, 'optionalAccess', _5 => _5.id]);
  });
  React.useEffect(() => {
    if (props.open) {
      reload();
    }
  }, [props.open]);
  const data = {} ;
  Object.keys(byWorker).forEach((key) => {
    //@ts-ignore
    data[key] = Object.groupBy(byWorker[key], (entry) => {
      return `${entry.entry.jahr}-${entry.entry.monat}`;
    });
  });
  const tdStyle = {
    padding: "12px 15px",
  };
  return (
    React.createElement(Drawer, {
      extra: [
        React.createElement(Button, {
          type: "link",
          icon: React.createElement(Icon, { icon: "fa:print", __self: this, __source: {fileName: _jsxFileName, lineNumber: 77}} ),
          onClick: () => print(), __self: this, __source: {fileName: _jsxFileName, lineNumber: 75}}
        ),
      ],
      ...props, __self: this, __source: {fileName: _jsxFileName, lineNumber: 73}}

      , React.createElement('div', { id: "costOverview", __self: this, __source: {fileName: _jsxFileName, lineNumber: 83}}
        , Object.keys(data).map((werkerID) => {
          return Object.keys(data[werkerID]).map((monthKey) => {
            const firsEntry = data[werkerID][monthKey][0];
            return (
              React.createElement('div', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 88}}
                , React.createElement(Typography.Text, { type: "secondary", __self: this, __source: {fileName: _jsxFileName, lineNumber: 89}}, `Akkord ${firsEntry.entry.werker.getUsername()} ${monthKey}`)

                , React.createElement('table', {
                  style: {
                    borderCollapse: "collapse",
                    margin: "25px 0",
                    fontSize: "0.9em",
                    fontFamily: "sans-serif",
                    width: "100%",
                  }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 91}}

                  , React.createElement('thead', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 100}}
                    , React.createElement('tr', {
                      style: {
                        backgroundColor: "var(--ant-primary-color)",
                        color: "#ffffff",
                        textAlign: "left",
                      }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 101}}

                      , React.createElement('th', { style: tdStyle, __self: this, __source: {fileName: _jsxFileName, lineNumber: 108}}, "Auftrag")
                      , React.createElement('th', { style: tdStyle, __self: this, __source: {fileName: _jsxFileName, lineNumber: 109}}, "Menge")
                      , React.createElement('th', { style: tdStyle, __self: this, __source: {fileName: _jsxFileName, lineNumber: 110}}, "Lohn pro Kilo"  )
                      , React.createElement('th', { style: tdStyle, __self: this, __source: {fileName: _jsxFileName, lineNumber: 111}}, "Akkordlohn")
                    )
                  )
                  , data[werkerID][monthKey].map((entry, index) => {
                    return (
                      React.createElement(React.Fragment, null
                        , React.createElement('tr', {
                          style: {
                            borderBottom: "1px solid #dddddd",
                          }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 117}}

                          , React.createElement('td', { style: tdStyle, __self: this, __source: {fileName: _jsxFileName, lineNumber: 122}}
                            , _optionalChain([entry, 'optionalAccess', _6 => _6.entry, 'optionalAccess', _7 => _7.auftrag, 'optionalAccess', _8 => _8.AUFNR]) || "Ohne Auftrag"
                          )
                          , React.createElement('td', { style: tdStyle, __self: this, __source: {fileName: _jsxFileName, lineNumber: 125}}
                            , _optionalChain([entry, 'access', _9 => _9.cost, 'optionalAccess', _10 => _10.produziertesGewicht, 'access', _11 => _11.toFixed, 'call', _12 => _12(2)]), " Kg"
                          )
                          , React.createElement('td', { style: tdStyle, __self: this, __source: {fileName: _jsxFileName, lineNumber: 128}}
                            , _optionalChain([entry, 'access', _13 => _13.cost, 'optionalAccess', _14 => _14.preisJeKilo, 'access', _15 => _15.toFixed, 'call', _16 => _16(2)]), " €/Kg"
                          )
                          , React.createElement('td', { style: tdStyle, __self: this, __source: {fileName: _jsxFileName, lineNumber: 131}}
                            , _nullishCoalesce(_optionalChain([entry, 'access', _17 => _17.cost, 'optionalAccess', _18 => _18.preisGesamt, 'access', _19 => _19.toFixed, 'call', _20 => _20(2)]), () => ( entry.error)), " ", "€"

                          )
                        )
                      )
                    );
                  })
                  , React.createElement(React.Fragment, null
                    , React.createElement('tr', {
                      style: {
                        borderBottom: "1px solid #dddddd",
                        borderTop: "2px solid #dddddd",
                      }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 140}}

                      , React.createElement('td', { style: tdStyle, colSpan: 3, __self: this, __source: {fileName: _jsxFileName, lineNumber: 146}}
                        , React.createElement('b', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 147}}, " Akkordlohn gesamt"  )
                      )
                      , React.createElement('td', { style: tdStyle, __self: this, __source: {fileName: _jsxFileName, lineNumber: 149}}
                        , React.createElement('b', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 150}}
                          , data[werkerID][monthKey]
                            .reduce((acc, entry) => {
                              return acc + (_nullishCoalesce(_optionalChain([entry, 'access', _21 => _21.cost, 'optionalAccess', _22 => _22.preisGesamt]), () => ( 0)));
                            }, 0)
                            .toFixed(2)
                          , " €"
                        )
                      )
                    )
                  )
                )
              )
            );
          });
        })
      )
    )
  );
};
function print() {
  const innerHtml = document.getElementById("costOverview").innerHTML;
  const mywindow = window.open("", "PRINT", "height=1080,width=1920");

  mywindow.document.write("<html><head><title>" + document.title + "</title>");
  mywindow.document.write("</head><body >");
  mywindow.document.write("<h1>" + document.title + "</h1>");
  mywindow.document.write(innerHtml);
  mywindow.document.write("</body></html>");

  mywindow.document.close(); // necessary for IE >= 10
  mywindow.focus(); // necessary for IE >= 10*/

  mywindow.print();
  mywindow.close();

  return true;
}

export default AkkordResultDrawer;
