import Parse from "parse";

















export class OD3_GTFS_Agency extends Parse.Object {
  static __initStatic() {this.className = "OD3_GTFS_Agency"}

  constructor(data) {
    super("OD3_GTFS_Agency", data );
  }

  get agency_email() {
    return super.get("agency_email");
  }
  set agency_email(value) {
    super.set("agency_email", value);
  }
  get agency_fare_url() {
    return super.get("agency_fare_url");
  }
  set agency_fare_url(value) {
    super.set("agency_fare_url", value);
  }
  get agency_id() {
    return super.get("agency_id");
  }
  set agency_id(value) {
    super.set("agency_id", value);
  }
  get agency_lang() {
    return super.get("agency_lang");
  }
  set agency_lang(value) {
    super.set("agency_lang", value);
  }
  get agency_name() {
    return super.get("agency_name");
  }
  set agency_name(value) {
    super.set("agency_name", value);
  }
  get agency_phone() {
    return super.get("agency_phone");
  }
  set agency_phone(value) {
    super.set("agency_phone", value);
  }
  get agency_timezone() {
    return super.get("agency_timezone");
  }
  set agency_timezone(value) {
    super.set("agency_timezone", value);
  }
  get agency_url() {
    return super.get("agency_url");
  }
  set agency_url(value) {
    super.set("agency_url", value);
  }
} OD3_GTFS_Agency.__initStatic();

Parse.Object.registerSubclass("OD3_GTFS_Agency", OD3_GTFS_Agency);
