import Parse from "parse";














export class OD3_BDE_ListEntry extends Parse.Object {
  static __initStatic() {this.className = "OD3_BDE_ListEntry"}

  constructor(data) {
    super("OD3_BDE_ListEntry", data );
  }

  get key() {
    return super.get("key");
  }
  set key(value) {
    super.set("key", value);
  }
  get list() {
    return super.get("list");
  }
  set list(value) {
    super.set("list", value);
  }
  get value() {
    return super.get("value");
  }
  set value(value) {
    super.set("value", value);
  }
} OD3_BDE_ListEntry.__initStatic();

Parse.Object.registerSubclass("OD3_BDE_ListEntry", OD3_BDE_ListEntry);
