import Parse from "parse";












export class OD3_SourceMeta extends Parse.Object {
  static __initStatic() {this.className = "OD3_SourceMeta"}

  constructor(data) {
    super("OD3_SourceMeta", data );
  }

  get kpiData() {
    return super.get("kpiData");
  }
  set kpiData(value) {
    super.set("kpiData", value);
  }
  get location() {
    return super.get("location");
  }
  set location(value) {
    super.set("location", value);
  }
  get name() {
    return super.get("name");
  }
  set name(value) {
    super.set("name", value);
  }
} OD3_SourceMeta.__initStatic();

Parse.Object.registerSubclass("OD3_SourceMeta", OD3_SourceMeta);
