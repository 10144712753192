import Parse from "parse";












export class OD3_GTFS_Wheelchair_Boarding extends Parse.Object {
  static __initStatic() {this.className = "OD3_GTFS_Wheelchair_Boarding"}

  constructor(data) {
    super("OD3_GTFS_Wheelchair_Boarding", data );
  }

  get condition() {
    return super.get("condition");
  }
  set condition(value) {
    super.set("condition", value);
  }
  get description() {
    return super.get("description");
  }
  set description(value) {
    super.set("description", value);
  }
  get value() {
    return super.get("value");
  }
  set value(value) {
    super.set("value", value);
  }
} OD3_GTFS_Wheelchair_Boarding.__initStatic();

Parse.Object.registerSubclass("OD3_GTFS_Wheelchair_Boarding", OD3_GTFS_Wheelchair_Boarding);
