import Parse from "parse";















export class AX_EventExchange extends Parse.Object {
  static __initStatic() {this.className = "AX_EventExchange"}

  constructor(data) {
    super("AX_EventExchange", data );
  }

  get auftragsnummer() {
    return super.get("auftragsnummer");
  }
  set auftragsnummer(value) {
    super.set("auftragsnummer", value);
  }
  get charge() {
    return super.get("charge");
  }
  set charge(value) {
    super.set("charge", value);
  }
  get maschine() {
    return super.get("maschine");
  }
  set maschine(value) {
    super.set("maschine", value);
  }
  get meldungsart() {
    return super.get("meldungsart");
  }
  set meldungsart(value) {
    super.set("meldungsart", value);
  }
  get produktionsnummer() {
    return super.get("produktionsnummer");
  }
  set produktionsnummer(value) {
    super.set("produktionsnummer", value);
  }
  get zeitstempel() {
    return super.get("zeitstempel");
  }
  set zeitstempel(value) {
    super.set("zeitstempel", value);
  }
} AX_EventExchange.__initStatic();

Parse.Object.registerSubclass("AX_EventExchange", AX_EventExchange);
