import Parse from "parse";























export class OWPlcItem extends Parse.Object {
  static __initStatic() {this.className = "OWPlcItem"}

  constructor(data) {
    super("OWPlcItem", data );
  }

  get address() {
    return super.get("address");
  }
  set address(value) {
    super.set("address", value);
  }
  get DeviceID() {
    return super.get("DeviceID");
  }
  set DeviceID(value) {
    super.set("DeviceID", value);
  }
  get enabled() {
    return super.get("enabled");
  }
  set enabled(value) {
    super.set("enabled", value);
  }
  get extraOptions() {
    return super.get("extraOptions");
  }
  set extraOptions(value) {
    super.set("extraOptions", value);
  }
  get label() {
    return super.get("label");
  }
  set label(value) {
    super.set("label", value);
  }
  get note() {
    return super.get("note");
  }
  set note(value) {
    super.set("note", value);
  }
  get onChange() {
    return super.get("onChange");
  }
  set onChange(value) {
    super.set("onChange", value);
  }
  get OWSource() {
    return super.get("OWSource");
  }
  set OWSource(value) {
    super.set("OWSource", value);
  }
  get source() {
    return super.get("source");
  }
  set source(value) {
    super.set("source", value);
  }
  get type() {
    return super.get("type");
  }
  set type(value) {
    super.set("type", value);
  }
  get unit() {
    return super.get("unit");
  }
  set unit(value) {
    super.set("unit", value);
  }
} OWPlcItem.__initStatic();

Parse.Object.registerSubclass("OWPlcItem", OWPlcItem);
