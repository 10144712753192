import Parse from "parse";

















export class OD3_Maintenance_Order extends Parse.Object {
  static __initStatic() {this.className = "OD3_Maintenance_Order"}

  constructor(data) {
    super("OD3_Maintenance_Order", data );
  }

  get description() {
    return super.get("description");
  }
  set description(value) {
    super.set("description", value);
  }
  get tickets() {
    return super.relation("tickets");
  }
  get title() {
    return super.get("title");
  }
  set title(value) {
    super.set("title", value);
  }
  get user() {
    return super.get("user");
  }
  set user(value) {
    super.set("user", value);
  }
} OD3_Maintenance_Order.__initStatic();

Parse.Object.registerSubclass("OD3_Maintenance_Order", OD3_Maintenance_Order);
