import Parse from "parse";





















export class OD3_Maintenance_Schedule extends Parse.Object {
  static __initStatic() {this.className = "OD3_Maintenance_Schedule"}

  constructor(data) {
    super("OD3_Maintenance_Schedule", data );
  }

  get description() {
    return super.get("description");
  }
  set description(value) {
    super.set("description", value);
  }
  get enabled() {
    return super.get("enabled");
  }
  set enabled(value) {
    super.set("enabled", value);
  }
  get media() {
    return super.relation("media");
  }
  get run_cron() {
    return super.get("run_cron");
  }
  set run_cron(value) {
    super.set("run_cron", value);
  }
  get run_timestamp() {
    return super.get("run_timestamp");
  }
  set run_timestamp(value) {
    super.set("run_timestamp", value);
  }
  get source() {
    return super.get("source");
  }
  set source(value) {
    super.set("source", value);
  }
  get tenant() {
    return super.get("tenant");
  }
  set tenant(value) {
    super.set("tenant", value);
  }
  get title() {
    return super.get("title");
  }
  set title(value) {
    super.set("title", value);
  }
} OD3_Maintenance_Schedule.__initStatic();

Parse.Object.registerSubclass("OD3_Maintenance_Schedule", OD3_Maintenance_Schedule);
