import Parse from "parse";










export class OD3_BDE_List extends Parse.Object {
  static __initStatic() {this.className = "OD3_BDE_List"}

  constructor(data) {
    super("OD3_BDE_List", data );
  }

  get list() {
    return super.get("list");
  }
  set list(value) {
    super.set("list", value);
  }
} OD3_BDE_List.__initStatic();

Parse.Object.registerSubclass("OD3_BDE_List", OD3_BDE_List);
