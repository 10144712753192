import Parse from "parse";


















export class OD3_Maintenance_Priority extends Parse.Object {
  static __initStatic() {this.className = "OD3_Maintenance_Priority"}

  constructor(data) {
    super("OD3_Maintenance_Priority", data );
  }

  get tenant() {
    return super.get("tenant");
  }
  set tenant(value) {
    super.set("tenant", value);
  }
  get ticket() {
    return super.get("ticket");
  }
  set ticket(value) {
    super.set("ticket", value);
  }
  get user() {
    return super.get("user");
  }
  set user(value) {
    super.set("user", value);
  }
  get value() {
    return super.get("value");
  }
  set value(value) {
    super.set("value", value);
  }
} OD3_Maintenance_Priority.__initStatic();

Parse.Object.registerSubclass("OD3_Maintenance_Priority", OD3_Maintenance_Priority);
