import Parse from "parse";


















export class OD3_GTFS_Calendar extends Parse.Object {
  static __initStatic() {this.className = "OD3_GTFS_Calendar"}

  constructor(data) {
    super("OD3_GTFS_Calendar", data );
  }

  get end_date() {
    return super.get("end_date");
  }
  set end_date(value) {
    super.set("end_date", value);
  }
  get friday() {
    return super.get("friday");
  }
  set friday(value) {
    super.set("friday", value);
  }
  get monday() {
    return super.get("monday");
  }
  set monday(value) {
    super.set("monday", value);
  }
  get saturday() {
    return super.get("saturday");
  }
  set saturday(value) {
    super.set("saturday", value);
  }
  get start_date() {
    return super.get("start_date");
  }
  set start_date(value) {
    super.set("start_date", value);
  }
  get sunday() {
    return super.get("sunday");
  }
  set sunday(value) {
    super.set("sunday", value);
  }
  get thursday() {
    return super.get("thursday");
  }
  set thursday(value) {
    super.set("thursday", value);
  }
  get tuesday() {
    return super.get("tuesday");
  }
  set tuesday(value) {
    super.set("tuesday", value);
  }
  get wednesday() {
    return super.get("wednesday");
  }
  set wednesday(value) {
    super.set("wednesday", value);
  }
} OD3_GTFS_Calendar.__initStatic();

Parse.Object.registerSubclass("OD3_GTFS_Calendar", OD3_GTFS_Calendar);
