import Parse from "parse";







































export class Kuenne_QATest extends Parse.Object {
  static __initStatic() {this.className = "Kuenne_QATest"}

  constructor(data) {
    super("Kuenne_QATest", data );
  }

  get AENBEN() {
    return super.get("AENBEN");
  }
  set AENBEN(value) {
    super.set("AENBEN", value);
  }
  get AENDAT() {
    return super.get("AENDAT");
  }
  set AENDAT(value) {
    super.set("AENDAT", value);
  }
  get AENDATETIME() {
    return super.get("AENDATETIME");
  }
  set AENDATETIME(value) {
    super.set("AENDATETIME", value);
  }
  get AENUHR() {
    return super.get("AENUHR");
  }
  set AENUHR(value) {
    super.set("AENUHR", value);
  }
  get ANZAUFM() {
    return super.get("ANZAUFM");
  }
  set ANZAUFM(value) {
    super.set("ANZAUFM", value);
  }
  get AUFNR() {
    return super.get("AUFNR");
  }
  set AUFNR(value) {
    super.set("AUFNR", value);
  }
  get BENID() {
    return super.get("BENID");
  }
  set BENID(value) {
    super.set("BENID", value);
  }
  get BESTEX() {
    return super.get("BESTEX");
  }
  set BESTEX(value) {
    super.set("BESTEX", value);
  }
  get BEZ() {
    return super.get("BEZ");
  }
  set BEZ(value) {
    super.set("BEZ", value);
  }
  get CHARGE() {
    return super.get("CHARGE");
  }
  set CHARGE(value) {
    super.set("CHARGE", value);
  }
  get CHGNR() {
    return super.get("CHGNR");
  }
  set CHGNR(value) {
    super.set("CHGNR", value);
  }
  get DATUM() {
    return super.get("DATUM");
  }
  set DATUM(value) {
    super.set("DATUM", value);
  }
  get DATUMDATETIME() {
    return super.get("DATUMDATETIME");
  }
  set DATUMDATETIME(value) {
    super.set("DATUMDATETIME", value);
  }
  get DEBARTNR() {
    return super.get("DEBARTNR");
  }
  set DEBARTNR(value) {
    super.set("DEBARTNR", value);
  }
  get DEBNR() {
    return super.get("DEBNR");
  }
  set DEBNR(value) {
    super.set("DEBNR", value);
  }
  get FREIPRF() {
    return super.get("FREIPRF");
  }
  set FREIPRF(value) {
    super.set("FREIPRF", value);
  }
  get IDNR() {
    return super.get("IDNR");
  }
  set IDNR(value) {
    super.set("IDNR", value);
  }
  get LFDNR() {
    return super.get("LFDNR");
  }
  set LFDNR(value) {
    super.set("LFDNR", value);
  }
  get MASNR() {
    return super.get("MASNR");
  }
  set MASNR(value) {
    super.set("MASNR", value);
  }
  get NAME() {
    return super.get("NAME");
  }
  set NAME(value) {
    super.set("NAME", value);
  }
  get NEUBEN() {
    return super.get("NEUBEN");
  }
  set NEUBEN(value) {
    super.set("NEUBEN", value);
  }
  get NEUDAT() {
    return super.get("NEUDAT");
  }
  set NEUDAT(value) {
    super.set("NEUDAT", value);
  }
  get NEUDATETIME() {
    return super.get("NEUDATETIME");
  }
  set NEUDATETIME(value) {
    super.set("NEUDATETIME", value);
  }
  get NEUUHR() {
    return super.get("NEUUHR");
  }
  set NEUUHR(value) {
    super.set("NEUUHR", value);
  }
  get ORT() {
    return super.get("ORT");
  }
  set ORT(value) {
    super.set("ORT", value);
  }
  get POSNR() {
    return super.get("POSNR");
  }
  set POSNR(value) {
    super.set("POSNR", value);
  }
  get PRUEFERGEBNIS() {
    return super.get("PRUEFERGEBNIS");
  }
  set PRUEFERGEBNIS(value) {
    super.set("PRUEFERGEBNIS", value);
  }
  get RINGNR() {
    return super.get("RINGNR");
  }
  set RINGNR(value) {
    super.set("RINGNR", value);
  }
  get SONSTIGES() {
    return super.get("SONSTIGES");
  }
  set SONSTIGES(value) {
    super.set("SONSTIGES", value);
  }
  get STATUS() {
    return super.get("STATUS");
  }
  set STATUS(value) {
    super.set("STATUS", value);
  }
} Kuenne_QATest.__initStatic();

Parse.Object.registerSubclass("Kuenne_QATest", Kuenne_QATest);
