import Parse from "parse";


















export class OD3_Source extends Parse.Object {
  static __initStatic() {this.className = "OD3_Source"}

  constructor(data) {
    super("OD3_Source", data );
  }

  get children() {
    return super.relation("children");
  }
  get meta() {
    return super.get("meta");
  }
  set meta(value) {
    super.set("meta", value);
  }
  get name() {
    return super.get("name");
  }
  set name(value) {
    super.set("name", value);
  }
  get parent() {
    return super.get("parent");
  }
  set parent(value) {
    super.set("parent", value);
  }
  get tag() {
    return super.get("tag");
  }
  set tag(value) {
    super.set("tag", value);
  }
  get tenant() {
    return super.get("tenant");
  }
  set tenant(value) {
    super.set("tenant", value);
  }
} OD3_Source.__initStatic();

Parse.Object.registerSubclass("OD3_Source", OD3_Source);
